<template>
  <div style="margin: 0 auto; text-align: center;">
    <el-date-picker
      v-model="value2"
      type="daterange"
      align="right"
      unlink-panels
      :range-separator="$t('All.至')"
      :start-placeholder="$t('All.开始日期')"
      :end-placeholder="$t('All.结束日期')"
      :picker-options="pickerOptions"
      format="yyyy 年 MM 月 dd 日">
    </el-date-picker>
    <el-button style="margin-left: 10px;" type="primary" @click="GetScheduling">{{ $t('All.查询') }}</el-button>
    <el-button style="margin-left: 10px;" v-show="excelpage.length > 0" type="primary" @click="Xiaz">{{$t('All.下载')}}(Exlce)</el-button>
    <template>
        <el-table :data="excelpage" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Gys" :label="$t('All.供应商')">
          </el-table-column>
          <el-table-column align="center" prop="Zongpiliang" :label="$t('All.总批数')">
          </el-table-column>
          <el-table-column align="center" prop="Hgp" sortable :label="$t('All.合格批')">
          </el-table-column>
          <el-table-column align="center" prop="Rsp" sortable :label="$t('All.允收批')">
          </el-table-column>
          <el-table-column align="center" prop="Qjp" sortable :label="$t('All.全检批')">
          </el-table-column>
          <el-table-column align="center" prop="Jsp" sortable  :label="$t('All.拒收批')">
          </el-table-column>
          <el-table-column align="center" prop="Hgl" sortable :label="$t('All.合格率')">
          </el-table-column>
          <el-table-column align="center" prop="Rsl" sortable :label="$t('All.允收率')">
          </el-table-column>
          <el-table-column align="center" prop="Qjl" sortable :label="$t('All.全检率')">
          </el-table-column>
          <el-table-column align="center" prop="Jsl" sortable :label="$t('All.拒收率')">
          </el-table-column>
          <el-table-column align="center" prop="Pf" sortable :label="$t('All.品质评分')">
          </el-table-column>
        </el-table>
      </template>
    <download-excel id="download_excel" class = "export-excel-wrapper" :data = "excelpage" :fields = "json_fields" :before-generate="beforegenerate" :before-finish="beforefinish" :name = "`${Date.now()}.xls`">
      <el-button v-show="false" type="primary" icon="download" > Download </el-button>
    </download-excel>
  </div>
</template>
<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: this.$t('All.最近一周'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近一个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('All.最近三个月'),
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      value1: '',
      value2: '',
      excelpage: [], // 存放用于导出excel的数据
      json_fields: {
        供应商: 'Gys', // 常规字段
        总批数: 'Zongpiliang', // 支持嵌套属性
        合格批: 'Hgp',
        允收批: 'Rsp',
        全检批: 'Qjp',
        拒收批: 'Jsp',
        合格率: 'Hgl',
        允收率: 'Rsl',
        全检率: 'Qjl',
        拒收率: 'Jsl',
        品质评分: 'Pf'
      },
      json_meta: [
        [
          {
            ' key ': ' charset ',
            ' value ': ' utf- 8 '
          }
        ]
      ]
    }
  },
  created () {
  },
  mounted () {
  },
  methods: {
    async GetScheduling () {
      const postdata = {
        UserData: this.$store.state.Login,
        Start: this.value2[0],
        End: this.value2[1]
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/GetIQCScheduling', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.$message.success(this.$t('All.数据不存在'))
          return
        }
        this.excelpage = res.response
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    Xiaz () {
      document.getElementById('download_excel').click()
    },
    beforegenerate () {
      console.log(this.$t('All.开始下载'))
    },
    beforefinish () {
      console.log(this.$t('All.下载完成'))
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  margin:0 auto;
  width: 300px;
}
</style>
